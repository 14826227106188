<template>
  <div class="sign-up-timer">
    <atomic-icon id="expires" class="sign-up-timer__icon" />
    <div class="sign-up-timer__label">{{ props.expiredLabel }}</div>
    <span class="sign-up-timer__item" :class="{ 'has-null': minutes === 0 }">
      <span class="sign-up-timer__item-digit">{{ format(minutes) }}</span>
    </span>

    <span class="sign-up-timer__dots">:</span>

    <span class="sign-up-timer__item" :class="{ 'has-null': seconds === 0 }">
      <span class="sign-up-timer__item-digit">{{ format(seconds) }}</span>
    </span>
  </div>
</template>

<script setup lang="ts">
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';

  dayjs.extend(utc);

  const props = defineProps<{
    expiredLabel: string;
    countdown: number;
  }>();

  const { minutes, seconds, startTimer } = useTimer();

  const format = (value: string | number): number | string => (Number(value) < 10 ? `0${value}` : value);

  onMounted(() => {
    const countMinutes = dayjs().add(props.countdown, 'minute').format();
    startTimer(countMinutes);
  });
</script>

<style src="~/assets/styles/components/sign-up/timer.scss" lang="scss" />

<template>
  <div :class="promoClasses">
    <div v-if="props.showTimer" class="promo__header">
      <sign-up-timer v-if="timerLabel && countMinutes" :expired-label="timerLabel" :countdown="countMinutes" />
    </div>
    <div class="promo__content">
      <div v-if="props.content?.title" class="promo__content-title">{{ props.content.title }}</div>
      <div
        v-if="props.content?.description"
        class="promo__content-description"
        v-html="
          DOMPurify.sanitize(marked.parseInline(props.content?.description || '') as string, { FORBID_TAGS: ['style'] })
        "
      />
    </div>
    <atomic-picture class="promo__img" :src="props.content?.image" />
  </div>
</template>

<script setup lang="ts">
  import type { CISignUpPage } from '~/types';
  import DOMPurify from 'isomorphic-dompurify';
  import { marked } from 'marked';

  const props = defineProps<{
    showTimer?: boolean;
    content: Maybe<CISignUpPage['bonusBanner']>;
  }>();

  const promoClasses = computed(() => ['promo', { 'promo--has-header': props.showTimer }]);

  const timerLabel = computed(() => {
    return props.content?.expiredLabel || '';
  });

  const countMinutes = computed(() => {
    return props.content?.countdown || 5;
  });
</script>

<style src="~/assets/styles/components/atomic/promo.scss" lang="scss" />
